import { render, staticRenderFns } from "./license.vue?vue&type=template&id=6767cff5&scoped=true&"
import script from "./license.vue?vue&type=script&lang=js&"
export * from "./license.vue?vue&type=script&lang=js&"
import style0 from "./license.vue?vue&type=style&index=0&id=6767cff5&lang=less&scoped=true&"
import style1 from "./license.vue?vue&type=style&index=1&id=6767cff5&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6767cff5",
  null
  
)

export default component.exports