<template>
  <div class="license">
    <img
      src="@assets/images/individualBusiness/logoffed.png"
      alt="已注销"
      class="logoffed"
      v-if="cancelInfo.state === '注销成功'"
    />
    <div class="box-wrap">
      <p class="title">个体工商户执照详情</p>
      <div class="content">
        <p>字号名称：<span>{{confirmInfo.busiRegdName}}</span></p>
        <p>注 册 号：<span>{{confirmInfo.busiRegdNo}}</span></p>
        <p>经营范围：<span>{{confirmInfo.busiRange}}</span></p>
        <p>经营者姓名：<span>{{confirmInfo.applyUserName}}</span></p>
        <p>经营场所：<span>{{confirmInfo.busiAddress}}</span></p>
      </div>
    </div>
    <div class="box-wrap">
      <p class="title">个体工商执照证件照</p>
      <div class="content">
        <div v-for="(item, index) in confirmInfo.busiLicenseImg" :key="index">
          <ImagePreview :imgUrl="item" alt="license" />
        </div>
      </div>
    </div>
    
    <div class='footer-bt'>
      <mt-button type='danger' size='large' @click.native='handleNext' v-if="this.state < 30 || this.cancelInfo.state === '注销成功'">确定</mt-button>
      <mt-button type='danger' size='large' @click.native='handleCancel' v-else-if="this.state >= 30 && (!this.cancelInfo.state || this.cancelInfo.state === '注销失败')">申请注销</mt-button>
      <mt-button type='danger' size='large' v-else>注销中</mt-button>
    </div>
  </div>
</template>

<script>
import { getConfirmInfo, getCancelInbu, getCancelInbuDetail } from "@api/individualBusiness";
import { Toast, MessageBox } from 'mint-ui';
import ImagePreview from '@components/image-preview';
import { getInBuState } from '@utils/common';
export default {
  name: "license",
  data() {
    return {
      confirmInfo: {},
      state: 0,
      cancelInfo: {}
    };
  },
  components: {
    ImagePreview
  },
  mounted() {
    this.handleGetConfirmInfo();
    this.handleGetCancelInfo();
  },
  beforeDestroy() {},
  computed: {},
  methods: {
    handleGetConfirmInfo() {
      getConfirmInfo().then(res => {
        if (res.code === 200) {
          const { busiLicenseImg, state } = res.data;
          this.state = getInBuState(state);
          if (busiLicenseImg) {
            res.data.busiLicenseImg = busiLicenseImg.split(',').map(item => {
              if (item.indexOf('/original') === -1) {
                item = item + '/original';
              }
              return item;
            })
          }
          this.confirmInfo = res.data;
        } else {
          Toast(res.message);
        }
      })
    },
    handleGetCancelInfo() {
      getCancelInbuDetail().then(res => {
        if (res.code === 200) {
          this.cancelInfo = res.data || {};
        }
      })
    },
    handleNext() {
      this.$router.back(-1);
    },
    handleCancel() {
      MessageBox.confirm('您确定要申请注销个体工商户吗?').then(action => {
        if (action === 'confirm') {
          getCancelInbu().then(res => {
            if (res.code === 200) {
              Toast('您已提交注销申请,请耐心等候')
              this.cancelInfo.state = '费用复核'
            } else {
              Toast(res.message);
            }
          })
        }
      }).catch(() => {
      });
    }
  }
};
</script>

<style lang="less" scoped>
*:not(.footer-bt) {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.license {
  padding: 4.267vw 4.8vw 110px;
  position: relative;
}
.logoffed {
  width: 26.6667vw;
  height: 20.5333vw;
  position: absolute;
  top: 1.0667vw;
  right: 3.2vw;
}
.box-wrap {
  width: 100%;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 1.8667vw;
  padding: 0 3.0667vw 3.0667vw;
  margin-bottom: 5.0667vw;
}
.title {
  height: 10.6667vw;
  font-size: 3.7333vw;
  color: rgba(102, 102, 102, 1);
  line-height: 10.6667vw;
  padding-left: 3vw;
  position: relative;
  border-bottom: 0.2667vw solid #e6e6e6;
  &::before {
    content: '';
    width: 0.8vw;
    height: 4.5333vw;
    background-color: #C92E26;
    position: absolute;
    top: calc(50% - 2.2vw);
    left: 0;
  }
  margin-bottom: 1.0667vw;
  overflow: hide;
}
.content {
  font-size: 3.7333vw;
  color: rgba(153, 153, 153, 0.8);
  line-height: 4.8vw;
  padding-top: 1.0667vw;
  p {
    margin-bottom: 2vw;
  }
  span {
    color: #666666;
  }
}
</style>
<style lang="less" scoped>
  .content {
    img {
      display: block;
      margin: 5.3333vw auto 0;
      width: 95%;
      object-fit: cover;
    }
  }
</style>